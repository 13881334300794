<template>
  <v-row class="ma-0" justify="center">
    <v-dialog
      v-model="show"
      @click:outside="closeHandler"
      max-width="360px"
      @keydown="
        value => {
          value.key === 'Escape' ? closeHandler() : '';
        }
      "
    >
      <template v-slot:activator="{ on }">
        <slot name="new-vehicle-activity" v-on="on"></slot>
      </template>

      <v-card>
        <v-card-title>
          <v-row class="ma-0" justify="center">
            <h3 class="main--text">
              {{ editMode ? translations.editActivities : translations.newActivity }}
            </h3>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-col v-if="!editMode" class="pa-0">
            <b>{{ translations.selectVehicle }}</b>
            <!-- @change="getBlockedDates" -->
            <v-autocomplete v-model="vehicleId" :search-input.sync="vehicleToSearch" :items="vehicles" item-text="code" item-value="id" :placeholder="translations.typeVehicleCode" outlined hide-details dense clearable>
              <template v-slot:selection="data">
                {{ `${data.item.code} - ${data.item.label} ` }}
              </template>

              <template v-slot:item="data">
                {{ `${data.item.code} - ${data.item.label} ` }}
              </template>

              <template v-slot:append-item>
                <v-row v-if="hasNextVehicles && !loadingVehicles" class="ma-0" justify="center">
                  <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" :size="20"></v-progress-circular>
                </v-row>
              </template>

              <template v-slot:no-data>
                <v-list-item>
                  {{ translations.noVehiclesWithThatCode }}
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col v-else class="pa-0">
            <v-row class="ma-0" align="center">
              <v-icon color="main" class="mr-2">{{ icons.mdiCarEstate }}</v-icon>

              <b>{{ translations.vehicleInformation }}</b>
            </v-row>

            <p class="mb-0 mt-2">
              <b>{{ translations.code }}:</b>
              {{ `${activityInfo.code} - ${activityInfo.label}` }}
            </p>
            <p class="mb-0">
              <b>{{ translations.license }}:</b> {{ activityInfo.license }}
            </p>
          </v-col>

          <v-col class="pa-0 mt-4">
            <b>{{ translations.status }}:</b>
            <v-radio-group v-model="statusId">
              <v-radio v-for="(status, index) in statusList" :key="index" :label="status.status" :value="status.id" :hide-details="true" dense class="mt-1"></v-radio>
            </v-radio-group>
          </v-col>

          <v-row class="ma-0 mt-8" align="center">
            <v-col class="pa-0" cols="2"
            ><b class="mr-2">{{ translations.from }}</b></v-col
            >
            <v-col class="pa-0">
              <date-picker
                @selected-date="
                  value => {
                    setDate(value, 'dateFrom');
                  }
                "
                :key="`a-${counter}`"
                :dateProp="dateFrom"
                :disablePicker="false"
                :classToAdd="['date-picker-component']"
              ></date-picker>

              <!-- Save do not delete -->
              <!-- :excludedDates="blockedDates" -->
            </v-col>
          </v-row>

          <v-row class="ma-0 mt-4" align="center">
            <v-col class="pa-0" cols="2"
            ><b class="mr-2">{{ translations.to }}</b></v-col
            >
            <v-col class="pa-0">
              <date-picker
                @selected-date="
                  value => {
                    setDate(value, 'dateTo');
                  }
                "
                :key="`b-${counter}`"
                :dateProp="dateTo"
                :minDateProp="dateFrom.picker"
                :startToDate="startToDate"
                :disablePicker="false"
                :classToAdd="['date-picker-component']"
              >
                <!-- Save do not delete -->
                <!-- :maxDateProp="maxDateTo"
                :excludedDates="blockedDates" -->
              </date-picker>
            </v-col>
          </v-row>

          <v-row v-if="displayDateError" class="ma-0 mt-4" justify="center" align="start">
            <v-col class="pa-0" cols="auto">
              <v-icon color="error" class="mr-2" small>{{ icons.mdiAlert }}</v-icon>
            </v-col>

            <v-col class="pa-0">
              <span class="error--text" style="font-size: .9rem">{{ translations.fromToDateError }}</span>
            </v-col>
          </v-row>

          <v-row class="ma-0 mt-2">
            <v-col class="pa-0">
              <b>{{ translations.notes }}:</b>
              <v-textarea v-model="note" filled flat dense hide-details></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-5 pt-0">
          <v-row class="ma-0" justify="center">
            <v-btn @click="activityRequestHandler" :disabled="disableBtn" color="main" class="white--text px-8" :loading="loading">{{ editMode ? translations.edit : this.translations.create }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiAlert, mdiCarEstate } from '@mdi/js'
import DatePicker from '@/components/DatePicker.vue'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'

import { translations } from '@/utils/common'

export default {
  components: {
    DatePicker
  },

  props: {
    statusList: {
      type: Array,
      required: true,
      dafault: () => []
    },

    show: {
      type: Boolean,
      default: () => false
    },

    activityInfo: {
      type: Object,
      default: () => {
      }
    },

    editMode: {
      type: Boolean,
      default: () => false
    },

    vehicles: {
      type: Array,
      default: () => [],
      required: true
    },

    hasNextVehicles: {
      type: Boolean,
      default: () => false
    },

    loadingVehicles: {
      type: Boolean,
      default: () => false
    },

    loading: {
      type: Boolean,
      default: () => false
    }
  },

  data: () => ({
    statusId: 1,
    counter: 0,
    dateFrom: {
      simple: '',
      holder: '',
      picker: ''
    },
    dateTo: {
      simple: '',
      holder: '',
      picker: ''
    },
    vehicleId: '',
    vehicleToSearch: '',
    startToDate: '',
    note: '',
    activityContent: []
  }),

  created() {
    if (!this.activityInfo?.dateFrom) return
    this.prepInfo()
  },

  mixins: [MomentMixin],

  methods: {
    setDate(value, type) {
      this[type].simple = value.simple
      this[type].holder = this.momentDate(value.simple, 'DD.MM.YYYY')
      this[type].picker = this.formatDate(value.simple)

      if (type === 'dateFrom') {
        this.startToDate = value.picker
      }
    },

    activityRequestHandler() {
      const vehicleActivity = {
        dateFromStr: this.dateFrom.simple ? this.formatDate(this.dateFrom.simple) : null,
        dateToStr: this.dateTo.simple ? this.formatDate(this.dateTo.simple) : null,
        vehicle: { id: this.vehicleId },
        note: this.note,
        vehicleStatus: {
          id: this.statusId,
          keyCode: this.statusList.find(req => req.id === this.statusId).keyCode
        }
      }
      if (this.editMode) vehicleActivity.id = this.activityInfo.id
      this.$emit('send-request', vehicleActivity)
    },

    closeHandler() {
      this.statusId = 1
      this.dateFrom = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.dateTo = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.vehicleId = ''
      this.counter++
      this.note = ''
      this.activityContent = []
      this.$emit('close')
    },

    prepInfo() {
      const { dateFromSimple, dateToSimple, statusId, note, vehicleId } = this.activityInfo
      //Simple
      this.dateFrom.simple = dateFromSimple
      this.dateTo.simple = dateToSimple
      //Holder text field
      this.dateFrom.holder = this.momentDate(dateFromSimple, 'DD.MM.YYYY')
      this.dateTo.holder = this.momentDate(dateToSimple, 'DD.MM.YYYY')
      //Picker
      this.dateFrom.picker = this.formatDate(dateFromSimple)
      this.dateTo.picker = this.formatDate(dateToSimple)
      this.statusId = statusId
      this.note = note
      this.vehicleId = vehicleId
      // this.getBlockedDates(vehicleId);
    },

    // async getBlockedDates(id) {
    //   try {
    //     const { data } = await this.$apollo.query({
    //       query: vehicleActivitiesBlockedDatesQuery,
    //       variables: {
    //         dateStr: null,
    //         page: 1,
    //         pageSize: 30,
    //         vehicleId: id,
    //         order: "asc",
    //         sort: "dateFrom"
    //       },
    //       fetchPolicy: "no-cache"
    //     });
    //     //Get Content
    //     this.activityContent = data.vehicleActivities.content;
    //   } catch (e) {
    //     // eslint-disable-next-line no-console
    //     console.log(e);
    //   }
    // }

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.$emit('fetch-vehicles')
      }
    }
  },

  computed: {
    icons: () => ({
      mdiAlert,
      mdiCarEstate
    }),

    translations: () => translations,

    disableBtn() {
      if (!this.statusId || !this.dateFrom.simple || !this.dateTo.simple || !this.vehicleId || this.dateFromIsAfterTo) return true
      return false
    },

    dateFromIsAfterTo() {
      if (!this.dateFrom.simple || !this.dateTo.simple) return true

      return this.dateFrom.simple > this.dateTo.simple
    },

    displayDateError() {
      if (this.dateFrom.simple && this.dateTo.simple && this.dateFromIsAfterTo) return true
      return false
    }

    // Save do not delete
    // blockedDates() {
    //   if (this.activityContent.length === 0) return [];
    //   let arrayToReturn = [];
    //   var getDaysArray = function(s, e) {
    //     for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
    //       a.push(new Date(d));
    //     }
    //     return a;
    //   };

    //   if (!this.editMode) {
    //     this.activityContent.forEach(activity => {
    //       const { dateFrom, dateTo } = activity;
    //       let daysList = getDaysArray(new Date(dateFrom), new Date(dateTo));
    //       daysList.forEach(day => {
    //         const formattedDay = this.formatDate(day);
    //         arrayToReturn.push(formattedDay);
    //       });
    //     });
    //   } else {
    //     const filteredActivities = this.activityContent.filter(
    //       activity => activity.id !== this.activityInfo.id
    //     );
    //     filteredActivities.forEach(activity => {
    //       const { dateFrom, dateTo } = activity;
    //       let daysList = getDaysArray(new Date(dateFrom), new Date(dateTo));
    //       daysList.forEach(day => {
    //         const formattedDay = this.formatDate(day);
    //         arrayToReturn.push(formattedDay);
    //       });
    //     });
    //   }

    //   return arrayToReturn;
    // },

    // Save do not delete
    // maxDateTo() {
    //   if (!this.dateFrom.picker) return "";
    //   if (this.blockedDates.length === 0) return "";
    //   let maxDate = "";

    //   for (var i = 0; i < this.blockedDates.length; i++) {
    //     if (this.blockedDates[i] > this.dateFrom.picker) {
    //       maxDate = this.blockedDates[i];
    //       break;
    //     }
    //   }

    //   return maxDate;
    // }
  },

  watch: {
    vehicleToSearch(value) {
      this.$emit('search-vehicle', value)
    },

    loading(newVal) {
      if (newVal === false) return this.closeHandler()
    }
  }
}
</script>
