<template>
  <main class="page-overflow ma-4">


    <!-- page header -->
    <div class="page-header-3 mx-2">

      <!-- page header: filters -->
      <div class="page-header-filters">
        <!-- vehicle filter -->
        <v-menu max-height="200px" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-on="on" v-bind="attrs"
              name="none"
              :placeholder="translations.typeVehicleCode"
              v-model="vehicleToSearch"
              :loading="$apollo.queries.vehicles.loading"
              autocomplete="off"
              clearable
              dense
              @click:clear="onVehicleChange(null)"
              ref="vehicleFilter"/>
          </template>
          <v-list>
            <v-list-item v-for="v in vehicleList" :key="v.id" @click="onVehicleChange(v)">
              {{ `${v.code} - ${v.label}` }}
            </v-list-item>

            <v-list-item v-if="vehicleList.length === 0">
              {{ translations.noVehiclesWithThatCode }}
            </v-list-item>
          </v-list>
          <div style="background: white;" class="d-flex justify-center" v-intersect="onIntersect" v-if="hasNextVehicles && !$apollo.queries.vehicles.loading">
            <v-progress-circular indeterminate color="main"></v-progress-circular>
          </div>
        </v-menu>

        <!-- empty filter -->
        <div></div>

        <!-- date filter -->
        <v-menu v-model="showPicker" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateLabel" :placeholder="translations.dateFilter"
                          v-on="on" v-bind="attrs" readonly clearable dense
                          @click:clear="() => onDateChange(null)"/>
          </template>
          <v-date-picker v-model="picker" @change="onDateChange" locale="it"/>
        </v-menu>
      </div>

      <!-- page header: title -->
      <div class="all-center-column">
        <h3 class="main--text">{{ translations.vehicleRequest }}</h3>

        <h6 class="main--text" style="cursor:pointer" @click="$router.push('/back-office')">
          <v-icon :size="10">mdi-arrow-left</v-icon>
          {{ translations.goTo }} {{ translations.employeeRequest }}
        </h6>
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <div class="all-center" style="width: 20%">
        </div>

        <div class="all-center" style="width: 60%">
          <!-- New Request Dialog -->
          <back-office-vehicle-activity-dialog :statusList="statusList || []"
                                               :show="showRequestDialog"
                                               :loading="loadingNewRequest"
                                               @close="showRequestDialog = false"
                                               :vehicles="vehicleList"
                                               :hasNextVehicles="hasNextVehicles"
                                               :loadingVehicles="$apollo.queries.vehicles.loading"
                                               @send-request="sendRequestHandler"
                                               @fetch-vehicles="fetchMoreVehicles"
                                               @search-vehicle="value => { vehicleToSearch = value; }">
            <template slot="new-vehicle-activity">
              <v-btn color="main" class="white--text" @click="showRequestDialog = true" height="32px" depressed>
                <v-icon class="mr-1">{{ icons.mdiPlus }}</v-icon>
                {{ translations.newActivity }}
              </v-btn>
            </template>
          </back-office-vehicle-activity-dialog>
        </div>
      </div>
    </div>


    <!-- main content -->
    <v-card class="ma-1">
      <v-data-table :headers="headers" :items="activities" :items-per-page="10"
                    class="page-table" fixed-header hide-default-footer :update:options="{ page: page, itemsPerPage: pageSize }">

        <!-- Code and label -->
        <template v-slot:item.code="{ item }">
          {{ item.label ? `${item.code} - ${item.label}` : item.code }}
        </template>

        <!-- Action Menus -->
        <template v-slot:item.status="{ item }">
          <v-row class="ma-0" align="center">
            <v-col class="pa-0 mr-2" cols="auto">
              <span>{{ item.status }}</span>
            </v-col>

            <v-col class="pa-0 mr-2 cursor-pointer" cols="auto">
              <v-icon @click="item.showDialog = true" small>{{ icons.mdiSquareEditOutline }}</v-icon>
              <back-office-vehicle-activity-dialog
                v-if="item.showDialog"
                :statusList="statusList || []"
                :show="item.showDialog"
                :vehicles="vehicleList"
                :activityInfo="item"
                :editMode="true"
                :loading="loadingNewRequest"
                @close="item.showDialog = false"
                @send-request="sendRequestHandler">
              </back-office-vehicle-activity-dialog>
            </v-col>

            <v-col class="pa-0" cols="auto">
              <v-icon v-if="!item.loading" @click="(item.loading = true), deleteRequestHandler(item.id)" small color="error lighten-1" class="cursor-pointer">{{ icons.mdiDelete }}</v-icon>
              <v-progress-circular v-else :width="3" :size="20" color="red" indeterminate></v-progress-circular>
            </v-col>
          </v-row>
        </template>

        <!-- footer -->
        <template v-slot:footer>
          <div class="d-flex justify-end align-center pa-2">
            <span class="mr-2">{{ page === 1 ? initialNumber : (page - 1) * 10 + 1 }} - {{ (page - 1) * 10 + activities.length }} di {{ totalRequests }}</span>

            <v-btn @click="onPreviousTableChange()" class="mr-2" icon :disabled="page === 1">
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            <v-btn @click="onNextTableChange" class="" icon :disabled="page === totalPages">
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>


    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <v-row class="ma-0" justify="center">
          <warning-svg :brandColor="mainColor" width="200px"></warning-svg>
        </v-row>
      </template>

      <template v-slot:title>
        <p class="mt-4">{{ translations.overlappingDatesError }}</p>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-end">
          <v-btn @click="showErrorMsgDialog = false" text small>{{ translations.close }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </main>
</template>

<script>
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'

import { mdiChevronLeft, mdiChevronRight, mdiDelete, mdiEye, mdiPlus, mdiSquareEditOutline } from '@mdi/js'

import { translations } from '@/utils/common'

// Queries and Mutations
import vehicleActivitiesQuery from '@/graphql/VehicleActivities.gql'
import vehicleStatusesQuery from '@/graphql/VehicleStatuses.gql'
import vehiclesQuery from '@/graphql/Vehicles.gql'
import saveVehicleActivityMutation from '@/graphql/SaveVehicleActivity.gql'
import deleteVehicleActivityMutation from '@/graphql/DeleteVehicleActivity.gql'

//Components
import BackOfficeVehicleActivityDialog from '@/views/BackOffice/BackOfficeVehicleActivityDialog.vue'

const MessageDialog = () => import('@/components/MessageDialog.vue')
const WarningSvg = () => import('@/assets/WarningSvg.vue')

export default {
  components: {
    BackOfficeVehicleActivityDialog,
    WarningSvg,
    MessageDialog
  },

  apollo: {
    vehicleActivities: {
      query: vehicleActivitiesQuery,
      variables() {
        return {
          page: this.page,
          pageSize: this.pageSize,
          dateStr: this.filterValues.date ? this.formatDate(this.filterValues.date) : null,
          vehicleStatusKeyCode: this.filterValues.vehicleStatusKeyCode,
          vehicleId: this.filterValues.vehicleId,
          sort: 'dateFrom',
          order: 'desc'
        }
      }
    },

    vehicleStatuses: {
      query: vehicleStatusesQuery
    },

    vehicles: {
      query: vehiclesQuery,
      variables() {
        return {
          page: 1,
          pageSize: 10,
          code: this.vehicleToSearch
        }
      },
      fetchPolicy: 'network-only'
    }
  },

  watch: {
    vehicleActivities() {
      this.totalRequests = this.vehicleActivities.totalElements
      this.totalPages = this.vehicleActivities.totalPages
      this.hasNext = this.vehicleActivities.hasNext

      const keyCodeAndValue = {
        REP: this.translations.repair
      }

      this.activities = this.vehicleActivities.content.map(activity => {
        const { dateFromStr, dateToStr, id, vehicle, vehicleStatus, note } = activity
        const { id: vehicleId, code, license, label } = vehicle
        const { keyCode } = vehicleStatus
        return {
          id: id,
          dateFrom: this.momentDate(dateFromStr, 'DD/MM/YYYY'),
          dateFromSimple: dateFromStr,
          dateToSimple: dateToStr,
          dateTo: this.momentDate(dateToStr, 'DD/MM/YYYY'),
          code: code,
          license: license,
          vehicleId: vehicleId,
          status: keyCodeAndValue[keyCode],
          statusId: vehicleStatus.id,
          loading: false,
          showDialog: false,
          note: note,
          label: label
        }
      })
    },

    vehicleStatuses() {
      const keyCodeAndValue = {
        REP: this.translations.repair
      }

      this.statusList = this.vehicleStatuses.map(status => {
        return {
          id: status.id,
          status: keyCodeAndValue[status.keyCode],
          keyCode: status.keyCode
        }
      })
    },

    vehicles() {
      this.vehicleList = this.vehicles.content
      this.hasNextVehicles = this.vehicles.hasNext
    }
  },

  data: () => ({
    totalRequests: null,
    totalPages: null,
    hasNext: null,
    activities: [],
    vehicleQueryVars: {
      page: 1,
      pageSize: 10
    },
    hasNextVehicles: true,
    page: 1,
    pageSize: 10,
    statusList: [],
    filterValues: {
      date: null,
      vehicleStatusKeyCode: null,
      vehicleId: null
    },
    vehicleToSearch: '',
    vehicleList: [],
    activeFilters: [],
    showRequestDialog: false,
    showPicker: false,
    picker: null,
    dateLabel: null,
    loadingNewRequest: false,
    showErrorMsgDialog: false
  }),

  created() {
    this.picker = this.getSimpleDay().substr(0, 10)
  },

  mixins: [MomentMixin],

  methods: {
    onVehicleChange(value) {
      this.page = 1
      this.filterValues.vehicleId = value ? value.id : null
      this.vehicleToSearch = value ? `${value.code} - ${value.label}` : ''
      if (!value) {
        this.$refs.vehicleFilter.blur()
      }
    },

    async sendRequestHandler(vehicleActivity) {
      this.loadingNewRequest = true

      try {
        await this.$apollo.mutate({
          mutation: saveVehicleActivityMutation,
          variables: {
            vehicleActivity
          }
        })

        this.$root.vtoast.show({ message: translations.activityHasBeenSaved })
        this.$apollo.queries.vehicleActivities.refetch()
      } catch (e) {
        this.showErrorMsgDialog = true
      }
      this.loadingNewRequest = false

      if (!this.hasNext) return
      this.page++
    },

    onNextTableChange() {
      if (!this.hasNext) return
      this.page++
    },

    onPreviousTableChange() {
      if (this.page === 1) return
      this.page--
    },

    async deleteRequestHandler(vehicleActivityId) {
      try {
        await this.$apollo
          .mutate({
            mutation: deleteVehicleActivityMutation,
            variables: {
              vehicleActivityId
            }
          })
          .catch(error => {
            this.handleError(error)
          })

        this.$apollo.queries.vehicleActivities.refetch()
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        await this.fetchMoreVehicles()
      }
    },

    async fetchMoreVehicles() {
      this.vehicleQueryVars.page++
      const res = await this.$apollo.query({
        query: vehiclesQuery,
        variables: { ...this.vehicleQueryVars, code: this.vehicleToSearch },
        fetchPolicy: 'network-only'
      })
      this.hasNextVehicles = res.data.vehicles.hasNext
      this.vehicleList.push(...res.data.vehicles.content)
    },

    onDateChange(date) {
      this.filterValues.date = date ? this.simpleFormat(date) : null
      this.page = 1
      this.dateLabel = date ? this.momentDate(date, 'DD/MM/YYYY') : null
      this.picker = date ? date : null
    }
  },

  computed: {
    headers() {
      return [
        {
          text: this.translations.code,
          align: 'start',
          sortable: false,
          value: 'code'
        },
        {
          text: this.translations.license,
          align: 'start',
          sortable: false,
          value: 'license'
        },
        {
          text: this.translations.dateFrom,
          align: 'start',
          sortable: false,
          value: 'dateFrom'
        },
        {
          text: this.translations.toDate,
          align: 'start',
          sortable: false,
          value: 'dateTo'
        },
        {
          text: this.translations.status,
          align: 'start',
          sortable: false,
          value: 'status'
        }
      ]
    },

    translations: () => translations,

    icons() {
      return {
        mdiPlus,
        mdiChevronRight,
        mdiChevronLeft,
        mdiSquareEditOutline,
        mdiDelete,
        mdiEye
      }
    },

    initialNumber() {
      if (this.activities.length === 0) return 0

      return 1
    },

    mainColor() {
      return this.$vuetify.theme.themes.light.main
    }
  }
}
</script>
